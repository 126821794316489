import React, { Component } from "react"
import {graphql } from "gatsby" // to query for image data
import Layout from "../components/layout"
import SlideShow from '../components/slideshow'

import sliderlist from "../content/sliderlist.json"

export default class MainIndex extends Component {


	state = {
		width: 1446,
		height: 606
	}
	
	componentDidMount() {
		this.handleResize()
		window.addEventListener('resize', this.handleResize)
	}
	componentWillUnmount() {
		window.removeEventListener('resize', this.handleResize)
	}

	handleResize = () => {
		this.setState(state => ({ 
			width: window.innerWidth,
			height: window.innerHeight
		}));
	};
	
	render() {

		const structuredData = {
			"@context": "https://schema.org",
			"@type": "Organization",
			"url": this.props.data.site.siteMetadata.siteUrl,
			"description": this.props.data.site.siteMetadata.description,
			"mainEntityOfPage": this.props.data.site.siteMetadata.title,
			"name": this.props.data.site.siteMetadata.title,
			"image": this.props.data.site.siteMetadata.siteUrl+"/images/logo.png",
			"logo": this.props.data.site.siteMetadata.siteUrl+"/images/logo-square.png",
			"@id": this.props.data.site.siteMetadata.siteUrl
		}
	
		const mobilewdhtratio = 483/493
		const desktopwdhtratio = 1446/606

		const wdhtratio= (this.state.width/this.state.height)
		const imgwdhtratio= (wdhtratio>=590/500)?desktopwdhtratio:mobilewdhtratio
		return (
			<Layout activePath='' pageTitle="Home" structuredData={structuredData} >
				<SlideShow baseurl={this.props.data.site.siteMetadata.siteUrl} cutoffwdhtratio={desktopwdhtratio} wdhtratio={wdhtratio} imgwdhtratio={imgwdhtratio} sliderlist={sliderlist} imagelist={imgwdhtratio===desktopwdhtratio ? this.props.data.desktop.edges : this.props.data.mobile.edges} />
			</Layout>
		)
	}

}

export const data = graphql`
	query sliderPhotos {
		site {
			siteMetadata {
				title
				description
				siteUrl
			}
		  }

		desktop: allImageSharp(
			filter: {fluid: {originalName: {glob: "desktop-slider*"}}}
			sort: { fields: [ fixed___originalName], order: ASC}
		){
			edges {
				node {
					fluid {
						originalImg
						originalName
					}
					id
				}
			}
		}
		mobile: allImageSharp(
			filter: {fluid: {originalName: {glob: "mobile-slider*"}}}
			sort: { fields: [ fixed___originalName], order: ASC}
		){
			edges {
				node {
					fluid {
						originalImg
						originalName
					}
					id
				}
			}
		}
	}
`

