import React, { Component } from 'react'
import * as styles from './style.module.css'
import {Link} from 'gatsby'
import {
	CarouselProvider,
	Dot,
	Slide,
	Slider,
	ButtonBack,
	ButtonNext,
	Image
} from "pure-react-carousel"
import 'pure-react-carousel/dist/react-carousel.es.css'

import arrowleft from "../../images/home/arrow-left.png"
import arrowright from "../../images/home/arrow-right.png"
import image_button from "./buttonplaceholder.png"

class SlideShow extends Component {
	state = {
		moving:false //for disabling automatic animation while user is navigating slideshow so that they won't double jump
	}

	disableAnimation = () => {
		this.setState({moving : !this.state.moving})
		setTimeout( ( ()=>this.setState({moving : !this.state.moving}) ), 2000)
	}


	render() {
		const {
			imagelist,
			itemcount,
			showDots,
			sliderlist,
			baseurl,
			imgwdhtratio,
			wdhtratio,
			cutoffwdhtratio,
			leftArrowImg,
			rightArrowImg,
			children,
			showloading,
			autoplay,
			clickfunc,
			childrenAsDot,
			customSlideClass,
			className
		}	= this.props
		const moving = this.state.moving
		return (
			<div className={wdhtratio>cutoffwdhtratio ? styles.slidewide:styles.slidetall }>
				<CarouselProvider
					naturalSlideWidth={imgwdhtratio}
					naturalSlideHeight={1}
					totalSlides={imagelist.length}
					isPlaying={!moving && autoplay}
					interval={5000}
					hasMasterSpinner={showloading}
					className={styles.container}
					infinite={true}
					visibleSlides={itemcount}
				>
					<Slider className={className}>
						{imagelist.map((entry, slideindex) =>
							<Slide className={customSlideClass} index={entry.node.id} >
								{
									clickfunc?
									<Image className={styles.slideclickable} src={entry.node.fluid.originalImg} isBgImage={true} onClick={()=>{
											clickfunc({slideindex})
										}}  />
									:
									<Image src={entry.node.fluid.originalImg} isBgImage={true}  />
								}
								{sliderlist[entry.node.fluid.originalName] !== undefined && <div class={styles.slidecontent}>
									{ sliderlist[entry.node.fluid.originalName].title &&
										<div class={`${styles.slidetitle} font-size-large font-style-title`} dangerouslySetInnerHTML={{__html:sliderlist[entry.node.fluid.originalName].title}}/>
									}
									{ sliderlist[entry.node.fluid.originalName].subtitle &&
										<div class={`${styles.slidesubtitle} font-size-medium`}>{sliderlist[entry.node.fluid.originalName].subtitle}</div>
									}
									{ sliderlist[entry.node.fluid.originalName].button &&
										<Link to={"/"+sliderlist[entry.node.fluid.originalName].buttonurl} className={`${styles.slidebutton} font-size-medium color-background-primary`}>
											{sliderlist[entry.node.fluid.originalName].button}
										</Link>
									}
								</div>}
								{ sliderlist[entry.node.fluid.originalName] !== undefined &&
									(
										sliderlist[entry.node.fluid.originalName].clickUrl &&
										<a href={sliderlist[entry.node.fluid.originalName].clickUrl.replace(/HOMEPAGEURL/g,baseurl)} className={`${styles.slideurl}`}>
											&nbsp;
										</a>
									)
								}
								{ sliderlist[entry.node.fluid.originalName] !== undefined  &&
									(
										sliderlist[entry.node.fluid.originalName].clickNav &&
										<Link to={"/"+sliderlist[entry.node.fluid.originalName].clickNav} className={`${styles.slideurl}`}>
											&nbsp;
										</Link>
									)
								}
							</Slide>
						)}
					</Slider>
					{
						itemcount < imagelist.length &&
						<>
							<ButtonBack
								className={`${styles.buttonBack} ${styles.button}`}
								onClick={this.disableAnimation}
							>
								<img src={leftArrowImg} alt='<'/>
							</ButtonBack>
							<ButtonNext
								className={`${styles.buttonNext} ${styles.button}`}
								onClick={this.disableAnimation}
							>
								<img src={rightArrowImg} alt='>'/>
							</ButtonNext>
						</>
					}
					{
						showDots &&
						<div
							className={`${styles.dotGroup}`}
						>
							{imagelist.map((data, index) =>
								<Dot slide={index}>
									<img className={styles.buttonplaceholder} src={image_button} alt=''/>
								</Dot>
							)}

							{children?children:null}
						</div>
					}
					{
						childrenAsDot === true &&  (children ?children:null)
					}
				</CarouselProvider>
			</div>
		)
	}
}

SlideShow.defaultProps = {
	autoplay: true,
	showloading: false,
	sliderlist:[],
	showDots: true,
	wdhtratio: 2,
	cutoffwdhtratio: 1,
	leftArrowImg: arrowleft,
	rightArrowImg: arrowright,
	itemcount: 1,
	clickfunc: null,
	childrenAsDot: false,
	customSlideClass: "",
	className: ""
}
export default SlideShow

